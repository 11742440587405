<template>
    <div class="report_add">
        <el-card>
            <el-form ref="form" :model="form" :rules="addFormRulue" label-width="auto">
                <el-form-item label="标题" prop="title">
                    <el-input v-model="form.title" :maxlength="100" show-word-limit />
                </el-form-item>
                <el-form-item label="描述" prop="desc">
                    <el-input v-model="form.desc" :maxlength="300" show-word-limit />
                </el-form-item>
                <el-form-item label="来源" prop="source">
                    <el-input v-model="form.source" :maxlength="50" show-word-limit />
                </el-form-item>
                <el-form-item label="地区" prop="areaCode">
                    <el-select v-model="form.areaCode" placeholder="请选择">
                        <el-option
                            v-for="(item, index) in area.reportArea"
                            :key="index"
                            :label="item"
                            :value="index * 1"
                            :data="action"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="年份" prop="year">
                    <el-select v-model="form.year" placeholder="请选择">
                        <el-option
                            v-for="(item, index) in area.reportYear"
                            :key="index"
                            :label="item"
                            :value="index * 1"
                            :data="action"
                        />
                    </el-select>
                </el-form-item>
               
                <el-form-item label="行业" prop="businessId">
                    <el-select v-model="form.businessId" placeholder="请选择">
                        <el-option
                            v-for="(item, index) in area.reportBusiness"
                            :key="index"
                            :label="item"
                            :value="index * 1"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="置顶">
                    <el-select v-model="form.top" placeholder="请选择">
                        <el-option label="是" :value="1" />
                        <el-option label="否" :value="0" />
                    </el-select>
                </el-form-item>
                <el-form-item label="专题">
                    <el-select v-model="form.tagId" multiple placeholder="请选择" >
                        <el-option v-for="item in projects" :key="item.id" :label="item.name" :value="item.id + ''" />
                    </el-select>
                </el-form-item>
                <el-form-item label="配图" prop="banner">
                    <up-img :action="action_1" :banner="form.banner" @event="setImg" />
                </el-form-item>
                <el-form-item label="PDF">
                    <el-upload
                        ref="pdf"
                        class="upload-demo"
                        :on-progress="beforeAvatarUpload"
                        :on-remove="word"
                        :action="this.$store.getters.imgUrl"
                        accept=".pdf"
                        :headers="header"
                        :on-success="handleAvatarSuccess"
                        :data="action"
                        :on-preview="load"
                        :limit="1"
                        :show-file-list="true"
                        :file-list="sd"
                    >
                        <el-button v-if="pdfstaus" size="small" type="primary">点击上传</el-button>
                        <button v-else size="small" type="primary" :disabled="true" class="lod">
                            加载中
                        </button>
                        <div slot="tip" class="el-upload__tip waring">
                            大小不可超过60M,只允许传pdf文件
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="是否可以下载源文件" prop="vipDownload">
                    <el-select v-model="form.vipDownload" placeholder="请选择">
                        <el-option label="是" :value="1" />
                        <el-option label="否" :value="0" />
                    </el-select>
                    <el-tooltip class="item" effect="dark" content="仅会员可下载" placement="top">
                        <i class="el-icon-question" style="position: relative;margin-left: 12px;"></i>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="支付方式">
                    <el-select v-model="form.type" @change="onChange" placeholder="请选择">
                        <el-option
                            :label="item.label"
                            :value="+item.value"
                            v-for="item in typeReport(reportPaymentType)"
                            :key="item.value"
                        ></el-option>
                    </el-select>
                    <el-checkbox
                        v-model="form.vipFree"
                        style="margin-left: 10px; position: relative; "
                        v-if="[3, 4, 2].includes(+form.type)"
                    >
                        会员免费查看
                    </el-checkbox>
                </el-form-item>
                <el-form-item label="支付金额" v-if="form.type > 1">
                    <el-col :span="8" :offset="0">
                        <!-- <el-input v-model="form.payAmount" /> -->

                        <el-select v-model="form.payAmount" v-if="form.type == 2">
                            <el-option
                                :label="item.title"
                                :value="`${item.price}`"
                                :key="index"
                                v-for="(item, index) in area.reportPrice"
                            >
                            </el-option>
                        </el-select>
                        <el-input v-model="form.payAmount" v-else />
                    </el-col>
                </el-form-item>
                <el-form-item label="赠送积分">
                    <el-col :span="8" :offset="0">
                        <el-input v-model="form.bonus" />
                    </el-col>
                    <br />
                    <div class="waring mt">
                        积分建议由运营根据平台生态合理填写。参考比例（人民币：帆币：积分 =
                        1：1：10）。eg：奖励5积分填写5。不填则按照积分规则统一配置。
                    </div>
                </el-form-item>
                <el-form-item label="试用链接">
                    <el-col :span="8" :offset="0">
                        <el-input v-model="form.marketingUrl" placeholder="输入客户产品推广链接" />
                    </el-col>
                </el-form-item>
                <el-form-item label="条款">
                    <el-col :span="8" :offset="0">
                        <el-input
                            v-model="form.clause"
                            type="textarea"
                            :rows="6"
                            placeholder="填写条款后，用户查看报告时会弹窗显示条款，用户必须阅读并同意此条款后才能查看报告。"
                        />
                    </el-col>
                </el-form-item>
                <el-form-item label="内容" :show-overflow-tooltip="true" prop="content">
                    <!-- <el-input v-model="form.content" type="textarea"></el-input> -->
                    <!-- <quill-editor ref="myQuillEditor" v-model="form.content" :options="quillOption" /> -->
                    <Tiny :value="form.content" @input="chang" />
                </el-form-item>
                <el-form-item>
                    <el-button v-if="!id" type="primary" @click="add">{{ btn ? "确 定" : "loading.." }}</el-button>
                    <el-button v-if="id" type="primary" @click="editadd">{{ btn ? "修 改" : "loading.." }}</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
// import Tiny from '../../components/Tinymce'
import upImg from "../../components/upImg";
import { reportPaymentType } from "@/utils/dictionary.js";
// console.log(reportPaymentType);
export default {
    components: {
        // Tiny,
        upImg,
    },
    data() {
        return {
            sd: [],
            form: {
                title: "",
                source: "",
                picture: "",
                content: "",
                desc: "",
                areaCode: "",
                banner: "",
                viewType: "",
                businessId: "",
                pdfUrl: "",
                top: "",
                tagId: "",
                marketingUrl: "",
                type: 3,
                payAmount: 50,
                vipFree: true,
                vipDownload: 1,
                // viewsRule: '10,100',
                initViews: Math.floor(Math.random() * (200 - 50 + 1)) + 50,
            },
            projects: [],

            pdfstaus: true,
            addFormRulue: {
                title: [{ required: true, message: "请输入标题", trigger: "blur" }],
                desc: [{ required: true, message: "请输入描述", trigger: "blur" }],
                source: [{ required: true, message: "请输入来源", trigger: "blur" }],
                areaCode: [{ required: true, message: "请输入地区", trigger: "blur" }],
                source: [{ required: true, message: "请输入来源", trigger: "blur" }],
                content: [{ required: true, message: "请输入内容", trigger: "blur" }],
                viewType: [{ required: true, message: "请输入查看方式", trigger: "blur" }],
                businessId: [{ required: true, message: "请输入行业", trigger: "blur" }],
                banner: [{ required: true, message: "请输入配图", trigger: "blur" }],
                content: [{ required: true, message: "请输入内容", trigger: "blur" }],
                year: [{ required: true, message: "请输入年份", trigger: "blur" }],
            },
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
            btn: true,
            action: {
                action: "report",
                type: "pdf",
            },
            action_1: {
                action: "report",
            },
            area: [],
            header: {
                Token: sessionStorage.getItem("token"),
            },
            id: null,
            reportPaymentType,
        };
    },

    created() {
        this.getea();
        this.getfrom();
        this.getProject();
        this.bus.$on("new", () => {
            this.form = {
                title: "",
                source: "",
                picture: "",
                content: "",
                desc: "",
                areaCode: "",
                banner: "",
                viewType: "",
                businessId: "",
                pdfUrl: "",
                viewsRule: "10,100",
                initViews: Math.floor(Math.random() * (200 - 50 + 1)) + 50,
            };
        });
    },
    watch: {
        
    },
    mounted() {
        // console.log(this.area);
        sessionStorage.setItem("action", "reportEditor");
    },
    methods: {
        setImg(val) {
            this.form.banner = val;
        },
        typeReport(val) {
            let arr = [3, 4, 2, 0, 1];
            arr = arr.map((item) => {
                // console.log(val[item]);
                return {
                    label: val[item],
                    value: item,
                };
            });
            return arr;
        },
        chang(val) {
            this.form.content = val;
            // console.log(val);
        },
        onChange(e) {
            if (e == 2) {
                this.form.payAmount = "";
            }
            if (e == 3) {
                this.form.vipFree = true;
            } else {
                this.form.vipFree = false;
            }
        },
        word() {
            this.pdfstaus = true;
        },
        beforeAvatarUpload() {
            this.pdfstaus = false;
        },
        getProject() {
            this.$http.get("/admin/Tag/getList?type=2").then(({ data: res }) => {
                // console.log(res);
                if (res.errorCode == 200) {
                    this.projects = res.data.list;
                    // this.$message.success(res.message)
                }
            });
        },
        getfrom() {
            if (this.$route.query.id) {
                var id = this.$route.query.id;
                this.id = id;
                this.$http.get("/admin/Report/getById?id=" + id).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        // console.log(res);
                        this.form = this.upData().set(res.data);
                        // console.log(this.form)
                        this.form.tagId = res.data.tagId.split(",");
                        this.form.id = id * 1;
                        // console.log(this.form);
                        //  this.form.initViews =
                        //   Math.floor(Math.random() * (200 - 50 + 1)) + 50
                    }
                });
            }
        },

        load() {
            // console.log(file);
            location.href = this.baseurl + this.yu + this.form.pdfUrl;
        },
        upData() {
            return {
                get(form) {
                    return {
                        ...form,
                        tagId: form.tagId?.toString(),
                        vipFree: form.vipFree ? 1 : 0,
                    };
                },
                set(data) {
                    return { ...data, vipFree: data.vipFree == 1 ? true : false };
                },
            };
        },
        add() {
            this.$refs.form.validate(() => {
                if (this.btn) {
                    this.$http.post("/admin/Report/add", this.upData().get(this.form)).then(({ data: res }) => {
                        if (res.errorCode == 200) {
                            this.$message.success("操作成功");
                            this.$router.push("/report_list");
                        }
                        this.btn = true;
                        // if(res.)
                    });
                }
                this.btn = false;
            });
        },
        editadd() {
            this.$refs.form.validate(() => {
                if (this.btn) {
                    this.$http
                        .post("/admin/Report/edit", this.upData().get({ ...this.form, id: this.id }))
                        .then(({ data: res }) => {
                            if (res.errorCode == 200) {
                                this.$message.success(res.message);
                                this.$router.push("/report_list");
                            }
                            this.btn = true;
                        });
                }
                this.btn = false;
            });
        },
        handleAvatarSuccess_1(res) {
            if (res.errorCode == 200) {
                this.form.banner = res.data.url;
            }
        },
        handleAvatarSuccess(res, file) {
            this.form.pdfUrl = "";
            if (res.errorCode == 200) {
                this.form.pdfUrl = res.data.url;
                this.$forceUpdate();
                // this.form=[...this.form]
            } else {
                this.$refs.pdf.clearFiles();
            }
            this.pdfstaus = true;
            console.log(file);
            //   console.log(imgurlbase)
        },
        beforeUpload() {
            if (this.pdfstaus) {
                return false;
            }
        },
        getea() {
            this.$http.get("/admin/ReportCategory/getList").then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.area = res.data;
                    // console.log(this.area);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    height: 178px;
    display: block;
}
.waring {
    color: #fda400;
    line-height: 24px;
    font-size: 12px;
}
.mt {
    margin-top: 10px;
}
.ml {
    margin-left: 10px;
}
.lod {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: not-allowed;
    background-color: #fda400;
    // background: #fff;
    border: 1px solid #dcdfe6;
    color: white;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: 0.1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 13px;
    border-radius: 4px;
}
</style>
<style>
.el-message.el-message--error {
    z-index: 20000 !important;
}
.el-select-dropdown.el-popper {
    z-index: 20000 !important;
}
</style>
